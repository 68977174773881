'use client'
import Link from "next/link";
import Image from "next/image";
import logo from '@/app/assets/logo-reassurez-moi.png'
import React, {createRef, useCallback, useEffect, useState} from "react";
import useIntersectionObserver from "@/app/hooks/useIntersectionObserver";
import StarsRating from "@/app/components/atomic/StarsRating";
import {useShallow} from "zustand/react/shallow";
import AppButton from "@/app/components/atomic/molecules/AppButton";
import {getNotation} from "@/app/endpoints/auctorEndpoints";
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'));

const MODULES = [
    'Logo',
    'Nav',
    'Actu',
] as const;

type Module = (typeof MODULES)[number] | React.FC

interface WithSections {
    start?: Module[],
    center?: Module[],
    end?: Module[],

    [key: string]: Module[] | undefined;
}

export interface IBaseHeader extends WithSections {
    [key: string]: any;

    children?: any,
    shouldDetach?: boolean,
    classes?: string,
    blockProps?: Partial<Record<(typeof MODULES)[number], any>>,
    responsive?: WithSections,
}

const SECTIONS_CLASSES: { [key: string]: string } = {
    start: "justify-self-start text-left",
    center: "justify-self-center text-center justify-center",
    end: "justify-self-end text-end justify-end",
}

function BaseHeader(props: IBaseHeader) {
    const bodyTopDiv = createRef<HTMLDivElement>()
    const {isIntersecting, IntersectionAnchor} = useIntersectionObserver(bodyTopDiv)
    const isHeaderIntersecting = props.shouldDetach ? isIntersecting : false

    const defaultIntersectingClass = props.shouldDetach
        ? 'lg:fixed min-w-full'
        : ''
    const headerIntersectingClass = !props.shouldDetach || isHeaderIntersecting
        ? ''
        : 'lg:min-w-[60%] lg:backdrop-blur-md lg:mt-2.5 lg:rounded-lg lg:bg-white/70'

    return <div className={`flex justify-center items-center min-h-20 h-[80px] ${props.classes}`}>
        <IntersectionAnchor />
        <header
            className={`${headerIntersectingClass} ${defaultIntersectingClass} flex content-center items-center justify-between px-3 min-h-20 h-[80px] lg:top-0 lg:right-0 lg:left-0
        !container mx-auto relative top-0 z-50 lg:transition-[min-width,opacity,margin,background-color,border-radius] lg:duration-500 bg-white/100`}
        >
            {
                (['start', 'center', 'end']).map((sectionName) => <RenderedSection
                    key={sectionName}
                    section={props[sectionName]}
                    blockProps={props.blockProps}
                    responsiveSection={props.responsive?.[sectionName]}
                    sectionName={sectionName}
                />)
            }
        </header>
    </div>
}

function RenderedSection({section, blockProps, sectionName, responsiveSection = null}: any) {
    const partClasses = "flex-row gap-4 items-center"
    return <>
        <div
            key={sectionName}
            className={`${partClasses} items-center ${SECTIONS_CLASSES[sectionName]} ${responsiveSection ? 'hidden' : 'flex'} rm-lg:flex`}
        >
            {getBlocksForSection({section, blockProps})}
        </div>
        {responsiveSection && <div
            key={`${sectionName}-responsive`}
            className={`${partClasses} items-center ${SECTIONS_CLASSES[sectionName]} flex rm-lg:hidden`}
        >
            {getBlocksForSection({section: responsiveSection, blockProps})}
        </div>}
    </>
}

const getBlocksForSection = ({section, blockProps}: any) => {
    return section?.map((component: Module, index: number) => (
        <div
            key={`module-${index}`}
            className={'flex justify-between items-center min-w-fit'}
        >
            <RenderedBlock
                component={component}
                key={index}
                index={index}
                useSmallVariant={section.length > 2}
                blockProps={blockProps}
            />
        </div>
    ))
}

function RenderedBlock(props: any) {
    const getFinalComponent = useCallback(() => {
        const {component}: { component: Module } = props
        // if (React.isValidElement(component)) return component
        const Component = typeof component === "string"
            ? (BaseHeader as any)[component]
            : component
        return <Component
            key={`block-${props.index}`}
            useSmallVariant={props.useSmallVariant}
            blockProps={props.blockProps}
        />
    }, [props.component])
    return getFinalComponent()
}

BaseHeader.Logo = React.memo(function Logo() {
    return <Link href='/'>
        <div className={"w-[170px] lg:w-[40px] xl:w-[170px] transition-all ease-in-out duration-100 overflow-hidden"}>
            <Image
                src={logo}
                alt='Comparateur Assurance'
                width={170}
                className='py-1 md:max-w-none'
            />
        </div>
    </Link>
})

function MobileNav() {
    const [isNavOpen, setIsNavOpen] = useState(false)

    return (
        <nav className='flex justify-end lg:hidden'>
            <i
                onClick={() => setIsNavOpen(!isNavOpen)}
                className="cursor-pointer fas fa-light fa-bars px-5 py-2 text-3xl text-blue-700"
            />
            {isNavOpen && (
                <>
                    <div
                        className="fixed top-0 right-0 z-50 h-screen bg-white lg:hidden p-8 flex flex-col justify-start gap-4 w-[65%]"
                    >
                        {NAV_LINKS.map(navLink => <DropDownMenu key={navLink.label} link={navLink} />)}
                        <div className="w-full h-[1px] bg-blue-700"></div>
                        {RM_LINKS.map(navLink => <DropDownMenu key={navLink.label} useSecondAccent link={navLink} />)}
                    </div>
                    <div className="overlay absolute">
                        <div
                            className="fixed top-0 right-0 left-0 z-30 h-screen w-screen bg-black/50"
                            onClick={() => setIsNavOpen(false)}
                        >
                            <div className="relative left-8 top-[48%] z-40 rounded-full bg-white h-12 w-12 flex items-center justify-center">
                                <AppIcon
                                    onClick={() => setIsNavOpen(false)}
                                    iconClassName="fa-times"
                                    size="2xl"
                                    className="text-blue-700 cursor-pointer"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </nav>
    )
}

BaseHeader.Nav = function Nav({}) {
    return <>
        <div className="flex lg:hidden bg-blue-100 rounded [&_i]:text-rm-12 h-[30px] w-[30px] justify-center">
            <MobileNav />
        </div>

        <nav
            className='hidden md:justify-end lg:!flex lg:items-stretch text-blue-700 text-sm xl:text-base font-semibold'
        >
            {NAV_LINKS.map(navLink => <DropDownMenu key={navLink.label} link={navLink} />)}
        </nav>
    </>
}

BaseHeader.Actu = function Actu({}) {
    return <>
        <MobileNav />
        <nav
            className='hidden md:justify-end lg:!flex lg:items-stretch text-blue-700 text-sm xl:text-base'
        >
            {RM_LINKS.map(navLink => <DropDownMenu key={navLink.label} link={navLink} />)}
        </nav>
    </>
}

const NAV_LINKS: NavLink[] = [
    {
        label: 'Assurance emprunteur',
        children: [
            {
                label: 'Comparateur assurance prêt immobilier',
                url: '/simulation-assurance-pret-immobilier',
            },
            {
                label: 'Changer d\'assurance prêt immobilier',
                url: '/guide/assurance-pret-immobilier/changer',
            },
            {
                label: 'Guide de l\'assurance de prêt',
                url: '/guide/assurance-pret-immobilier',
            },
        ],
    },
    {
        label: 'Prêt Immobilier',
        children: [
            {
                label: 'Taux prêt immobilier',
                url: '/taux-pret-immobilier',
            },
            {
                label: 'Simulation prêt immobilier',
                url: '/guide/pret-immobilier/simulation',
            },
            {
                label: 'Guide du prêt immobilier',
                url: '/guide/pret-immobilier',
            },
        ],
    },
    {
        label: 'Mutuelle santé',
        children: [
            {
                label: 'Comparateur mutuelle santé',
                url: '/comparateur-mutuelle-sante',
            },
            {
                label: 'Changer de mutuelle',
                url: '/guide/mutuelle-sante/changer',
            },
        ],
    },
    {
        label: 'Prévoyance',
        children: [
            {
                label: 'Assurance décès',
                url: '/guide/assurance-deces',
            },
            {
                label: 'Assurance obsèques',
                url: '/guide/assurance-obseques',
            },
            {
                label: 'Assurance dépendance',
                url: '/guide/assurance-dependance',
            },
        ],
    },
    {
        label: 'Assurance Voyage',
        children: [
            {
                label: 'Comparateur assurance voyage',
                url: '/guide/assurance-voyage',
            },
            {
                label: 'Assurance annulation voyage',
                url: '/guide/assurance-voyage/annulation-voyage',
            },
        ],
    },
    {
        label: 'Nos assurances',
        children: [
            {
                label: 'Rachat de crédit',
                url: '/guide/rachat-credit',
            },
            {
                label: 'Assurance vélo',
                url: '/guide/assurance-loisirs/velo',
            },
            {
                label: 'Assurance trottinette électrique',
                url: '/guide/assurance-loisirs/trottinette',
            },
            {
                label: 'Assurance auto',
                url: '/guide/assurance-auto',
            },
            {
                label: 'Assurance VTC',
                url: '/guide/assurance-auto/vtc',
            },
            {
                label: 'Assurance animaux',
                url: '/guide/assurance-chien-chat',
            },
            {
                label: 'Assurance habitation',
                url: '/guide/assurance-habitation/meilleures',
            },
            {
                label: 'Toutes nos assurances',
                url: '/guide',
            },
        ],
    },
];
const RM_LINKS: NavLink[] = [
    {
        label: 'A propos',
        children: [
            {
                label: 'Pourquoi nous ?',
                url: '/notre-offre',
            },
            {
                label: 'Notre équipe',
                url: '/notre-equipe',
            },
            {
                label: 'Espace Presse',
                url: '/guide/communication',
            },
            {
                label: 'Actualités',
                url: '/guide/actualites',
            },
            {
                label: 'Nos avis',
                url: '/avis',
            },
            {
                label: 'Nous contacter',
                url: '/contact',
            },
        ],
    },
];

type NavLink = {
    label: string,
    url?: string,
    mobileIcon?: string,
    children?: NavLink[],
}

function DropDownMenu({link, useSecondAccent = false}: { link: any, useSecondAccent?: boolean }) {
    const menuProps = {...link.url && {href: link.url}}
    const subMenuClasses = 'absolute right-0 top-full z-10 top-[120%] group-hover:opacity-100 opacity-0 group-hover:pointer-events-auto group-hover:h-min'

    return (
        <ul
            className={`transition-all cursor-pointer group relative flex md:flex-1 flex-col flex-nowrap items-center gap-1.5 px-1 py-2 mx-1 rounded
            hover:bg-gradient-to-r hover:from-blue-200/50 hover:to-blue-200 hover:outline outline-blue-200 justify-between outline-1 ${useSecondAccent ? 'text-blue-600' : ''}`}
        >
            <li>
                {link.url ? <a
                        className='flex flex-nowrap justify-between w-full items-center gap-1 xl:gap-2.5 whitespace-pre z-20'
                        {...menuProps}
                    >
                        {link.label} {link.children && <AppIcon size="sm" iconClassName="fa-chevron-down" />}
                    </a>
                    :
                    <span
                        className='flex flex-nowrap justify-between w-full items-center gap-1 xl:gap-2.5 whitespace-pre z-20'
                        {...menuProps}
                    >
                        {link.label} {link.children && <AppIcon size="sm" iconClassName="fa-chevron-down" />}
                    </span>
                }
            </li>

            {link.children && (
                <ul
                    className={`rounded-[8px] bg-white whitespace-nowrap px-[5px] group-hover:py-[5px] shadow-[0px_2px_8px_#00000026] h-0 overflow-hidden 
                    transition-[opacity] duration-500 ease-in-out pointer-events-none ${subMenuClasses} z-30 text-right`}
                >
                    {link.children?.map((sublink: NavLink) => (
                        <li key={sublink.label}>
                            <a
                                href={sublink.url}
                                className='text-sm block text-blue-700 hover:bg-blue-100 hover:no-underline p-[5px] rounded font-normal'
                            >
                                {sublink.label}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </ul>
    )
}

const MemoedHeader = React.memo(BaseHeader)
export default MemoedHeader
